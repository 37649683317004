.app {
  text-align: center;
  min-height: 100vh;
  background-color: #7B9CD4;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.app-header {
  padding: 4rem 2rem;
  background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 100%);
}

.app-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(120deg, #ffffff, #a8a8a8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.video-section {
  margin: 2rem auto;
}

.video-section h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  background: #1a1a1a;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-caption {
  margin-top: 1.5rem;
  font-size: 1.1rem;
  color: #000000;
  line-height: 1.6;
}

.app-footer {
  padding: 2rem;
  color: #666;
  font-size: 0.9rem;
  position: relative;
  bottom: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .app-header h1 {
    font-size: 2.5rem;
  }
  
  .video-section h2 {
    font-size: 1.5rem;
  }
  
  .main-content {
    padding: 1rem;
  }
}
